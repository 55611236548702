<!--
  -  Copyright (C) Healabs 2022 - All Rights Reserved
  -  Unauthorized copying of this file, via any medium is strictly prohibited
  -  Proprietary and confidential
  -
  -->

<template>
  <v-menu v-model="menu" :close-on-content-click="false" transition="scale-transition" offset-y min-width="auto">
    <template v-slot:activator="{on , attrs }">
      <text-field :value="formattedValue" readonly clearable v-bind="{...attrs, ...$attrs}"
                  @click:clear="localValue = null" v-on="on"
      />
    </template>
    <v-date-picker v-model="localValue" no-title scrollable v-bind="datePickerProps" :max="birthDate ? now : null"
                   :active-picker.sync="activePicker"
                   @input="menu =false"
    />
  </v-menu>
</template>

<script>
  import TextField from '@/modules/core/components/TextField'

  export default {
    name: 'DatePicker',
    components: {TextField},
    props: {
      value: String,
      datePickerProps: Object,
      birthDate: Boolean
    },
    data() {
      return {
        now: this.$dayjs().format('YYYY-MM-DD'),
        menu: false,
        activePicker: null,
        localValue: this.value
      }
    },
    computed: {
      formattedValue() {
        return this.localValue ? this.$dayjs(this.localValue).format('LL') : ''
      }
    },
    watch: {
      menu(value) {
        if (value && this.birthDate) {
          this.$nextTick(() => {
            this.activePicker = 'YEAR'
          })
        }
      },
      value(value) {
        this.localValue = value
      },
      localValue(value) {
        this.$emit('input', value)
      }
    }
  }
</script>

<style scoped>

</style>
